import React from "react"
import { graphql } from "gatsby"
import Single from "../components/single"

export default ({ data }) => (
  <Single
    slug="terms_of_use"
    title="利用規約"
    description="Puzzle.dev を利用するにあたって同意いただく必要がある利用規約です"
  >
    <div
      className="pt2"
      dangerouslySetInnerHTML={{ __html: data.file.childMarkdownRemark.html }}
    />
  </Single>
)

export const query = graphql`
  query {
    file(relativePath: { eq: "pages/terms_of_use.md" }) {
      childMarkdownRemark {
        html
      }
    }
  }
`
